import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img1.png'
import img2 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_gif1.gif'
import img3 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img2.png'
import img4 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img3.png'
import img5 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img4.png'
import img6 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img5.png'
import img7 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img6.png'
import img8 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img7.png'
import img9 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img8.png'
import img10 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img9.png'
import img11 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img10.png'
import img12 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img11.png'
import img13 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img12.png'
import img14 from '../../assets/images/to-poletje-bo-nord/topoletjebonord_img13.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { theme, colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionCounterImage,
  SectionImagesGrid,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import {
  HeadlineL,
  HeadlineM,
  TitleL,
  TitleM,
  SubtitleM,
  ParagraphL,
  ParagraphM,
  ParagraphS,
} from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'Video produkcija',
    'Offline oglaševanje',
    'Tekstopisje',
    'Družbena omrežja',
    'Grafično oblikovanje',
    'Video produkcija',
    'Offline oglaševanje',
    'Tekstopisje',
    'Družbena omrežja',
    'Grafično oblikovanje',
    'Video produkcija',
    'Offline oglaševanje',
    'Tekstopisje',
    'Družbena omrežja',
    'Grafično oblikovanje',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | To poletje bo Nord"
        description="Za prvo poletje, ko je na trgu Nord Hard Seltzer, smo zasnovali gverilsko kampanjo, ki se je začela z zunanjim oglaševanjem in se preko QR kode nadaljevala na spletu."
        image={img1}
      />
      <Header
        logoBackground={colors.blue}
        logoBorder={colors.blue}
        linkText={colors.blue}
        menuLines={colors.blue}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="To poletje bo Nord" />
          </div>
          <Square background={colors.yellow} className="title">
            <ParagraphM color={colors.white}>Nord drinks</ParagraphM>
            <HeadlineM color={colors.blue}>To poletje bo Nord</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.blue}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Z omejenimi sredstvi izpeljati večkanalno kampanjo, vključno z zunanjim oglaševanjem.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Za prvo poletje, ko je na trgu Nord Hard Seltzer, smo zasnovali gverilsko kampanjo, ki
              se je začela z zunanjim oglaševanjem in se preko QR kode nadaljevala na spletu.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-top-padding">
            <Content>
              {/* Example Title */}
              <h2>Mestni plakati</h2>

              {/* Example Paragraph */}
              <p>
                Ob začetku poletja smo zakupili mestni plakat na Ajdovščini v Ljubljani. Mimoidoče
                je plakat nagovarjal, naj nanj zapišejo kaj bo za njih to poletje. Že po parih urah
                je bil plakat popolnoma popisan.
              </p>

              <p>
                Po nekaj dneh smo na plakat dodali nalepko z napisom NORD, tako da je beseda Nord
                povzela vse pričakovane stvari poletja.
              </p>

              <p>S tem se je prvič pojavil slogan “To poletje bo Nord”.</p>

              {/* Example Image */}
              <div className="align-center">
                <img
                  src={img2}
                  alt="To poletje bo Nord"
                  style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}
                />
              </div>

              <p>
                Načrte so nam nato prekrižali petkovi protestniki, ki so iz plakata strgali nalepko,
                a smo zagato hitro obrnili v svoj prid. K sodelovanju smo povabili umetnika Roka
                Korenčana, ki je plakatu dal novo podobo s kompasom, ki kaže na Nord.
              </p>

              <p>
                Tako je plakat v 14-ih dneh večkrat spremenil podobo, kar je vzbudilo pozornost
                mimoidočih.
              </p>

              {/* Example Title */}
              <h2>Pristajalna stran in zgodba</h2>

              <p>
                Ob skeniranju QR kode smo uporabnika preusmerili na posebno pristajalno stran, kjer
                je bil zapisan tekst s katerim smo ciljno skupino nagovarjali tudi v videu.
              </p>

              {/* Example Image */}
              <img src={img3} alt="To poletje bo Nord" />

              {/* Example Title */}
              <h2>Video oglas</h2>

              <p>
                V sodelovanju z in:spire studiom smo posneli video oglas. Pri projektu smo
                sodelovali še z Balkan Campers in mladimi slovenskimi glasbeniki, ki pokrivajo
                podobno ciljno skupino.
              </p>

              {/* Example Video */}
              <iframe
                src="https://www.youtube.com/embed/AwflrW1l188"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>

              {/* Example Title */}
              <h2>Družbena omrežja</h2>

              <p>
                Vpliv mestnega plakata ne bi bil tako velik brez vzporedne komunikacije na družbenih
                omrežjih. Besede, ki so jih ljudje pisali na plakat, smo nato uporabili v oglasih.
              </p>

              {/* Example Image */}
              <div className="align-center">
                <img
                  src={img4}
                  alt="To poletje bo Nord"
                  style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}
                />
              </div>

              <p>
                Več o kampanji si lahko preberete v članku v{' '}
                <a
                  href="https://www.marketingmagazin.si/aktualno/nord-poletje-je-stanje-duha"
                  target="_blank"
                >
                  Marketing Magazinu
                </a>
                .
              </p>
            </Content>
          </SectionRichText>

          {/* CounterImages Section - Conditionally visible */}
          <SectionCounterImage>
            <div className="image">
              <img src={img5} alt="To poletje bo Nord" />
            </div>
            <Square background={colors.purple} className="counter">
              <HeadlineL color={colors.white} font={theme.fontSecondary}>
                50000+
              </HeadlineL>
              <SubtitleM color={colors.white}>ogledov videa</SubtitleM>
            </Square>
          </SectionCounterImage>
          <SectionCounterImage className="reverse">
            <div className="image">
              <img src={img6} alt="To poletje bo Nord" />
            </div>
            <Square background={colors.red} className="counter">
              <HeadlineL color={colors.white} font={theme.fontSecondary}>
                484
              </HeadlineL>
              <SubtitleM color={colors.white}>skeniranih QR kod</SubtitleM>
            </Square>
          </SectionCounterImage>

          {/* ImagesGrid Section - Conditionally visible */}
          <SectionImagesGrid>
            <div className="image">
              <img src={img9} alt="To poletje bo Nord" />
            </div>
            <div className="image">
              <img src={img11} alt="To poletje bo Nord" />
            </div>
            <div className="image">
              <img src={img8} alt="To poletje bo Nord" />
            </div>
            <div className="image">
              <img src={img12} alt="To poletje bo Nord" />
            </div>
            <div className="image">
              <img src={img7} alt="To poletje bo Nord" />
            </div>
            <div className="image">
              <img src={img10} alt="To poletje bo Nord" />
            </div>
            <div className="image">
              <img src={img13} alt="To poletje bo Nord" />
            </div>
            <div className="image">
              <img src={img14} alt="To poletje bo Nord" />
            </div>
          </SectionImagesGrid>
        </div>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          <Link to="/project/slovenia-green">
            <ParagraphL>← Prejšnji projekt </ParagraphL>
          </Link>
          <Link to="/project/vitasis">
            <ParagraphL>Naslednji projekt →</ParagraphL>
          </Link>
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
